<template>
  <div>
    <b-row>
      <b-col md="8">
        <b-card-group deck>
          <b-card no-body>
            <template #header style="padding: 1em !important; ">
                <div class="d-flex">
                  <router-link class="text-danger" to="./product-request" size="xs" title="CANCEL">
                    <feather-icon icon="CornerUpLeftIcon" size="20"/></router-link
                  >
                  <h4 class="mb-0 pl-1">AVAILABLE PRODUCTS</h4>
                </div>
                <div>
                  <b-input-group>
                    <template #append>
                      <b-input-group-text
                        ><strong>
                          <feather-icon
                            icon="SearchIcon"
                          />
                        </strong></b-input-group-text
                      >
                    </template>
                    <b-form-input
                      v-model="searchProduct"
                      placeholder="Search by Product Name"
                    ></b-form-input>
                  </b-input-group>
                </div>
            </template>
          </b-card>
        </b-card-group>

        <div
          :style="
            filteredProducts.length > 8
              ? 'height: 800px; overflow-y: scroll; padding: 1em;'
              : ''
          "
        >
          <b-row>
            <b-col
              v-for="(product, index) in filteredProducts"
              :key="index"
              @click="selectProduct(product, index)"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mt-2"
            >
              <b-card
                :border-variant="product.quantity >= 1 ? 'primary' : ''"
                no-body
                :img-src="
                  product.url_image || 'https://placekitten.com/1000/1000'
                "
                img-alt="Image"
                img-top
                img-height="200px"
                class="cursor-pointer"
                :header="product.product"
                header-class="h4 border-top-info"
              >
                <b-card-body>
                  <!-- <b-card-sub-title class="mb-2">Card Sub Title</b-card-sub-title> -->
                  <b-card-text
                    style="
                      height: 60px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    "
                  >
                    {{ product.description }}
                  </b-card-text>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <!-- :class="{ red: price <= 100, green: price > 100 }" -->
      <b-col md="4">
        <b-card-group deck>
          <b-card no-body>
            <template #header>
              <h4 class="mb-0">LIST PRODUCTS</h4>
              <div>
                <span class="feather-icon position-relative">
                  <feather-icon icon="ShoppingBagIcon" size="20" />
                  <span
                    v-if="arrayListProducts.length != 0"
                    class="badge badge-up badge-pill badge-important"
                    >{{ arrayListProducts.length }}</span
                  >
                </span>
                <feather-icon
                  icon="Trash2Icon"
                  size="20"
                  v-if="arrayListProducts.length != 0"
                  class="ml-2 cursor-pointer text-danger"
                  @click="removeAllProducts()"
                />
              </div>
            </template>
          </b-card>
        </b-card-group>

        <div
          :style="
            arrayListProducts.length > 6
              ? 'height: 700px; overflow: scroll;'
              : ''
          "
        >
          <b-card
            v-for="(list, index) in arrayListProducts"
            :key="index"
            style="margin: 2px"
          >
            <b-card-title>
              {{ list.product }}
              <feather-icon
                icon="XIcon"
                size="20"
                class="float-right text-danger cursor-pointer"
                @click="removeProduct(list, index)"
              />
            </b-card-title>
            <b-card-text>
              <div class="d-flex justify-content-between">
                <b-input-group style="width: 100px">
                  <b-input-group-prepend>
                    <tabler-icon
                      icon="MinusIcon"
                      size="28"
                      class="cursor-pointer"
                      @click="decreaseNumber(list, index)"
                    />
                  </b-input-group-prepend>

                  <b-form-input
                    size="sm"
                    type="number"
                    v-model="list.quantity"
                  ></b-form-input>

                  <b-input-group-append>
                    <tabler-icon
                      icon="PlusIcon"
                      size="28"
                      class="cursor-pointer"
                      @click="increaseNumber(list)"
                    />
                  </b-input-group-append>
                </b-input-group>
                <div>
                  <span>{{ list.currency }} {{ list.cost }}</span>
                </div>
                <div>
                  <span
                    >{{ list.currency }}
                    {{ (list.quantity * list.cost).toFixed(2) }}</span
                  >
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div class="text-center pt-1">
          <b-button
            variant="success"
            @click="sendList()"
            v-if="arrayListProducts.length > 0"
            >SEND LIST ( S/ {{ totalSumPen }} ) | ( $ {{ totalSumDollar }})
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// store
import { mapState, mapGetters } from "vuex";
//components

//services
import RequestProductService from "@/views/commons/components/product-request/services/request-product.service";

export default {
  components: {
    //
  },
  created() {
    // this.openMe(true)
  },
  data() {
    return {
      observation: "",
      products: null,
      arrayListProducts: [],
      searchProduct: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    totalSumPen() {
      let sum = this.arrayListProducts.reduce((accumulator, object) => {
        return (
          accumulator +
          (object.currency == "S/" ? Number(object.quantity * object.cost) : 0)
        );
      }, 0);
      return sum.toFixed(2);
    },
    totalSumDollar() {
      let sum = this.arrayListProducts.reduce((accumulator, object) => {
        return (
          accumulator +
          (object.currency == "$" ? Number(object.quantity * object.cost) : 0)
        );
      }, 0);
      return sum.toFixed(2);
    },

    filteredProducts: function () {
      var product_array = this.products,
        searchProduct = this.searchProduct;

      if (!searchProduct) {
        return product_array;
      }
      searchProduct = searchProduct.trim().toLowerCase();
      product_array = product_array.filter(function (item) {
        if (item.product.toLowerCase().indexOf(searchProduct) !== -1) {
          return item;
        }
      });
      return product_array;
    },
  },
  mounted() {
    if (!localStorage.getItem("user-logged")) {
      this.$router.push("./product-request");
    }
  },
  methods: {
    async sendList() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            arrayListProducts: this.arrayListProducts,
            user_id: this.currentUser.user_id,
            modul_id: this.currentUser.modul_id,
            totalSumPen: this.totalSumPen,
            totalSumDollar: this.totalSumDollar,
          };
          const data = await RequestProductService.saveProductsList(params);
          if (data.status === 200) {
            this.showSuccessSwal();
            this.$router.push("./product-request");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    close() {
      this.$emit("close");
    },
    async myProvider() {
      try {
        const params = {
          name_text: null,
          from: null,
          to: null,
          orden: "created_at",
          orderby: "desc",
          page: null,
          perpage: 1000,
        };
        const response = await RequestProductService.getProductsInventory(
          params
        );
        this.products = response.data;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    selectProduct(product, index) {
      if (!product.quantity) {
        this.$set(product, "quantity", 1);
        this.arrayListProducts.push(product);
      }
    },
    removeProduct(item, index) {
      this.arrayListProducts.splice(index, 1);
      this.products.forEach((element) => {
        if (item.id == element.id) {
          element.quantity = 0;
        }
      });
    },
    async removeAllProducts() {
      const confirm = await this.showGenericConfirmSwal({});

      if (confirm.value) {
        this.arrayListProducts = [];
        this.products.forEach((element) => {
          element.quantity = 0;
        });
      }
    },
    decreaseNumber(item, index) {
      this.$set(item, "quantity", Number(item.quantity) - 1);
      if (item.quantity == 0) {
        this.removeProduct(item, index);
      }
    },
    increaseNumber(item) {
      this.$set(item, "quantity", Number(item.quantity) + 1);
    },
  },
  created() {
    this.myProvider();
  },
};
</script>