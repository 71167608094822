import { amgApi } from "@/service/axios";

class RequestProductService{

    async getProductsInventory(parameters){
        try{
            const data = await amgApi.post('logistics/product-request/get-products-inventory',parameters)
            return data.data
        }catch(error){
            console.log(error)            
        }
    }

    async saveProductsList(parameters){
        try{
            const data = await amgApi.post('logistics/product-request/save-products-list',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async getProductsRequest(parameters){
        try{
            const data = await amgApi.post('logistics/product-request/get-products-request',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async getProductsFromList(parameters){
        try{
            const data = await amgApi.post('logistics/product-request/get-products-from-list',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async saveListDeliveryDate(parameters){
        try{
            const data = await amgApi.post('logistics/product-request/save-list-delivery-date',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async deliveredList(parameters){
        try{
            const data = await amgApi.post('logistics/product-request/delivered-list',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

}

export default new RequestProductService
