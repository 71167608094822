<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="breadcrumbs-top mb-2 d-flex">
        <h2 class="content-header-title float-left pr-1 mb-0">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item to="/">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              />
            </b-breadcrumb-item>
            <b-breadcrumb-item
              v-for="item in $route.meta.breadcrumb"
              :key="item.text"
              :active="item.active"
              :to="item.to"
            >
              {{ item.text }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
      <div>
        <b-button variant="primary" @click="goBack">
          <feather-icon
            icon="CornerUpLeftIcon"
            size="15"
          />
          Return</b-button>
      </div>
    </div>
    <div>
      <b-row class="d-flex">
        <b-col md="8">
          <div class="d-flex justify-content-between  border-bttm pb-1 justify-items-center">
            <div class="d-flex align-items-center">
              <h4 class="m-0">PRODUCT'S LIST</h4>
              <feather-icon
                icon="RefreshCcwIcon"
                size="15"
                class="cursor-pointer ml-1"
                @click="refreshData()"
              />
            </div>
            <div>
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="searchProduct"
                  placeholder="Search by name"
                  @keyup.enter="myProvider"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="myProvider">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>

          <div>
            <b-row>
              <b-col
                v-for="(product, index) in products"
                :key="index"
                @click="selectProduct(product, index)"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                class="mt-2"
              >
                <b-card
                  no-body
                  :img-src="product.path || '/assets/images/no_image.png'"
                  img-alt="Image"
                  img-fluid               
                  class="cursor-pointer"
                  :header="product.product"
                  header-class="h4 border-top-info shadow"
                  img-height="100 rem"
                >
                  <b-card-body>
                    <b-card-text>
                      <p class="m-0"><strong>{{ product.name }}</strong></p>
                      <p class="m-0">Stock: {{product.stock}} units</p>
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <div class="m-auto">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="onChangeCurrentPage($event)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-col>
        <!-- :class="{ red: price <= 100, green: price > 100 }" -->
        <b-col md="4" class="mh-100">          
          <div class="d-flex justify-content-between padding border-bttm pb-1">
            <div>
              
              <h4>
                <feather-icon
                  icon="ShoppingCartIcon"
                  size="15"
                  class="text-primary"
                />
                PRODUCTS SELECTED: 
                <span
                  v-if="arrayListProducts.length != 0"
                  >{{ arrayListProducts.length }}
                </span>     
              </h4>
            </div>
            <div>
              <feather-icon
                icon="Trash2Icon"
                size="20"
                v-if="arrayListProducts.length != 0"
                class="cursor-pointer text-danger"
                @click="removeAllProducts()"
              />
            </div>
          </div>

          <div
            :style="
              arrayListProducts.length > 3
                ? 'height: 550px; overflow: scroll;'
                : ''
            "
            class="pt-2 px-1"
          >
            <ValidationObserver ref="form">
            <b-card
              v-for="(list, index) in arrayListProducts"
              :key="index"
              border-variant="primary"
            >
              <b-card-text>
                <div class="pb-1">
                  <strong>{{ list.name }}</strong> 
                  <b-badge 
                  pill 
                  variant="primary"
                  class="float-right cursor-pointer"
                  @click="removeProduct(list, index)"
                  >x</b-badge>
                </div>                
                <div style="width: 180px;">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button
                        variant="outline-primary"
                        style="width: 30px; height: 35px"
                        class="p-0"
                        @click="list.quantity <= 1 ? list.quantity : list.quantity--"
                      >
                        <tabler-icon icon="MinusIcon" size="10" />
                      </b-button>
                    </b-input-group-prepend>
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="validate-percentage-min"
                    >
                    <b-form-input
                      type="number"
                      v-model="list.quantity"
                      style="width: 100px; height: 35px"
                      class="text-center no-spinners"
                      min="1"
                      :state="errors[0] ? false : null"
                    />
                    </ValidationProvider>
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        style="width: 30px; height: 35px"
                        class="p-0"
                        @click="list.quantity++"                        
                      >
                        <tabler-icon icon="PlusIcon" size="10" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>                
              </b-card-text>
            </b-card>
            </ValidationObserver>
          </div>
          <div class="text-center">
            <div v-if="arrayListProducts.length > 0" class="px-1">
              <b-form-textarea
                v-model="note"
                placeholder="Add note..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
            <div class="pt-1">
              <b-button                
                variant="primary"
                @click="sendList()"
                v-if="arrayListProducts.length > 0"
                > SEND REQUEST
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RequestService from "../services/request.service"
export default {
  data() {
    return {
      observation: "",
      products: null,
      arrayListProducts: [],
      searchProduct: "",
      showNoteModal: false,
      position: null,
      note: null,
      indexSelected: null,
      typeModal: null,
      productSelected: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 12,
      startPage: 1,
      toPage: '',
      totalData: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async sendList() {
      const validation = await this.$refs.form.validate();
      if (validation) {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          try {
            const params = {
              products: this.arrayListProducts,
              requested_by: this.currentUser.user_id,
              module_id: this.currentUser.modul_id,
              message: this.note
            };
            this.addPreloader();
            const data = await RequestService.addProductRequest(params);
            if (data.success) {
              this.showSuccessSwal();
              this.goBack();
            }
            this.removePreloader();
          } catch (error) {
            console.log(error);
            this.removePreloader();
          } finally {
            this.removePreloader();
          }
        }
      }
    },

    close() {
      this.showNoteModal = false;
    },
    async myProvider() {
      this.addPreloader();
      try {
        const params = {
          p_search: this.searchProduct,
          currentPage: this.currentPage,
          perPage: this.perPage,
        };
        
        const {data} = await RequestService.getProducts(
          params
        );
        this.currentPage = data.current_page;
        this.totalRows = data.total;
        this.perPage = data.per_page;
        this.startPage = data.from;
        this.toPage = data.data.last_page;
        this.totalData = data.total;
        this.products = data.data;
        this.removePreloader();
      } catch (e) {
        console.log(e);
        this.showErrorSwal(error);
        this.removePreloader();
        return [];
      }
    },
    qtyModifier(value, type){
      if(type == 'plus'){
        return value++;
      }else{
        return value <= 0 ? value : value--;
      }
    },
    selectProduct(product, index) {
      if(!this.arrayListProducts.some(item => item.id == product.id)){
        if (!product.quantity) {
          this.$set(product, "quantity", 1);
          this.arrayListProducts.push(product);
        }
      }else{
        this.showToast(
          "success",
          "top-right",
          "Message",
          "CheckIcon",
          "This product has already been selected"
        );
      }
    },
    removeProduct(item, index) {
      this.arrayListProducts.splice(index, 1);
      this.products.forEach((element) => {
        if (item.id == element.id) {
          element.quantity = 0;
        }
      });
    },
    async removeAllProducts() {
      const confirm = await this.showGenericConfirmSwal({});
      if (confirm.value) {
        this.arrayListProducts = [];
        this.products.forEach((element) => {
          element.quantity = 0;
        });
      }
    },
    onChangeCurrentPage(page){
      this.currentPage = page;
      this.myProvider();
    },
    decreaseNumber(item, index) {
      this.$set(item, "quantity", Number(item.quantity) - 1);
      if (item.quantity == 0) {
        this.removeProduct(item, index);
      }
    },
    increaseNumber(item) {
      this.$set(item, "quantity", Number(item.quantity) + 1);
    },
    refreshData(){
      this.searchProduct = "";
      this.myProvider();
    },
    goBack() {
      window.history.back();
    }
  },
  created() {
    this.myProvider();
  },
};
</script>
<style>
.border-bttm{
  border-bottom: 3px solid #0090e7;
}
.shadow{
  box-shadow: 60px -16px teal;
}
.padding{
  padding-top: 10px;
}
</style>